import { send } from "../index";

export function getFilmList(data, opts = {}) {
  //  获取最近播放的影片列表
  return send({
    url: "/admin/cinema/getFilmList.do",
    method: "POST",
    data,
    ...opts,
  });
}
export function getScheduleDate(data, opts = {}) {
  //  获取影片可售日期
  return send({
    url: "/admin/cinema/getScheduleDate.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getShowInfo(data, opts = {}) {
  //  获取影片演出信息
  return send({
    url: "/admin/cinema/showInfo.do",
    method: "POST",
    data,
    ...opts,
  });
}
export function getScheduleList(data, opts = {}) {
  //  获取影片排期
  return send({
    url: "/admin/cinema/getScheduleList.do",
    method: "POST",
    data,
    ...opts,
  });
}
export function getSeatInfo(data, opts = {}) {
  //  座位信息
  return send({
    url: "/admin/cinema/seatInfo.do",
    method: "POST",
    data,
    ...opts,
  });
}
export function getAllocate(data, opts = {}) {
  // 已售
  return send({
    url: '/admin/cinema/allocate.do',
    method: "POST",
    data,
    ...opts,
  })
}
export function getCinemaDetail(data, opts = {}) {
  // 获取影院详情
  return send({
    url: '/admin/cinema/detailApp.do',
    method: "POST",
    data,
    ...opts,
  })
}
export function getMyInfo(data, opts = {}) {
  // 获取用户信息
  return send({
    url: '/admin/user/getMyInfo.do',
    method: "POST",
    data,
    ...opts,
  })
}
export function lockSeat(data, opts = {}) {
  // 锁座
  return send({
    url: '/admin/preOrder/lockSeat.do',
    method: "POST",
    data,
    ...opts,
  })
}
export function preOrderTicket(data, opts = {}) {
  // 预下单
  return send({
    url: '/admin/preOrder/ticket.do',
    method: "POST",
    data,
    ...opts,
  })
}

export function getCardLowestPrice(data, opts = {}) {
  // 会员卡最低价格
  return send({
    url: '/admin/preOrder/cardLowestPrice.do',
    method: "POST",
    data,
    ...opts,
  })
}

export function getCardList(data, opts = {}) {
  // 次年卡
  return send({
    url: '/admin/userCard/searchListApp.do',
    method: "POST",
    data,
    ...opts,
  })
}
export function getCouponList(data, opts = {}) {
  // 兑换券
  return send({
    url: '/admin/voucher/searchList.do',
    method: "POST",
    data,
    ...opts,
  })
}
export function orderTicket(data, opts = {}) {
  // 购票下单支付
  return send({
    url: '/admin/order/orderTicket.do',
    method: "POST",
    data,
    ...opts,
  })
}

export function releaseSeat(data, opts = {}) {
  // 释放座位
  return send({
    url: '/admin/preOrder/releaseSeat.do',
    method: "POST",
    data,
    ...opts,
  })
}

export function printTicket(data, opts = {}) {
  // 打印pdf
  return send({
    url: '/admin/order/printTicket.do',
    method: "POST",
    data,
    ...opts,
  })
}

export function collectTickets(data, opts = {}) {
  // 打印pdf
  return send({
    url: '/admin/ticketOrder/collectTickets.do',
    method: "POST",
    data,
    ...opts,
  })
}









